import QueryInterface from '@/core/application/query/QueryInterface'

export default class LayerGeoJsonQuery implements QueryInterface
{
    private layerId: number;

    private filter: string[][] | object | null;

    constructor(layerId: number, filter: string[][] | object | null)
    {
        this.layerId = layerId;
        this.filter = filter;
    }

    getLayerId(): number
    {
        return this.layerId;
    }

    getFilter(): string[][] | object | null
    {
        return this.filter;
    }

    getClassName(): string
    {
        return 'LayerGeoJsonQuery';
    }
}